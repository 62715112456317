@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.css";

body {
  background: url(/images/bg-pattern-light.jpg); }

.nav-tabs {
  .nav-item {
    margin-right: 7px; } }

.border-2 {
  border: 2px solid; }

.bg-light-transparent {
  background: rgba(210, 210, 210, 0.4); }

.main-container {
  @extend .container;
  min-height: 90vh;
  padding: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.95); }

.custom-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      width: 100%;
      text-align: center; } } }
